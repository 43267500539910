<template>
  <div
    class="absolute top-2 right-0 left-0 bottom-0 mx-auto text-others-black text-xxs flex items-center justify-center"
  >
    {{ cartTotalItems }}
  </div>
</template>

<script setup>
const { getTotalItems } = useCart()
const cartTotalItems = getTotalItems()
</script>
