<template>
  <div
    class="absolute top-0 left-0 w-full h-full bg-white transition-all duration-300 z-60"
    :class="content ? 'translate-x-0' : 'translate-x-full'"
  >
    <div class="flex items-center justify-between p-4 w-full bg-white shadow">
      <button type="button" class="w-5 h-5" @click="emit('beforeValue')">
        <img
          src="@/assets/svg/icons/icons-arrow-right-light.svg"
          class="w-5 h-5 rotate-180"
        />
      </button>

      <div class="flex items-center gap-1">
        <span class="text-base font-bold">{{
          content?.name != null ? content?.name : content?.category_name
        }}</span>
      </div>
      <button type="button" class="w-5 h-5" @click="emit('close')">
        <img
          src="@/assets/svg/icons/icons-close.svg"
          class="w-5 h-5 rotate-180"
        />
      </button>
    </div>
    <div
      class="flex flex-col items-start w-full divide-y-1 h-full overflow-y-scroll pb-20"
    >
      <MenuMobileItem
        v-for="category in children"
        :key="category.category_id"
        :section="category"
        @openSubmenu="emit('openSubmenu', $event)"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  content: Object,
})

const children = computed(() => {
  if (!props.content) return []
  if (props.content?.children?.children)
    return props.content?.children?.children
  if (props.content?.children) return props.content?.children
  return []
})

const emit = defineEmits(['openSubmenu', 'close', 'beforeValue'])
</script>
