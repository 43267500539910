<template>
  <div class="w-full hidden">
    <div
      class="flex items-center justify-between w-full px-3 py-6"
      v-if="children && children?.length == 0"
    >
      <NuxtLink
        :to="section?.seotag ? `/${locale}/${section?.seotag}` : ''"
        class="text-xs font-bold text-others-black"
        >{{
          section?.name != null ? section?.name : section?.category_name
        }}</NuxtLink
      >
    </div>
    <div class="w-full px-3 py-6" v-else-if="children && children.length > 0">
      <div class="flex items-center justify-between w-full">
        <NuxtLink
          :to="section.seotag ? `/${locale}/${section.seotag}` : ''"
          class="text-xs font-bold text-others-black"
          >{{
            section?.name != null ? section?.name : section?.category_name
          }}</NuxtLink
        >
      </div>

      <MenuMobileLinks
        v-for="category in children"
        :key="category.category_id"
        :section="category"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  section: Object,
})

const { locale } = useI18n()

const children = computed(() => {
  if (!props.section) return []
  if (props.section?.children?.children)
    return props.section?.children?.children
  if (props.section?.children) return props.section?.children
  return []
})
</script>
