<template>
  <div>
    <div>
      <div
        class="flex flex-col max-w-1400 mx-auto md:px-9 lg:px-0 relative z-50"
        :class="isMobileOrTablet ? 'xl:px-9' : ''"
      >
        <header
          class="px-4 my-2 md:my-6 h-45 flex items-center flex-wrap z-50"
          :class="isMobileOrTablet ? 'xl:px-4' : 'xl:px-0'"
        >
          <div class="flex flex-grow basis-0 gap-3 items-center">
            <div class="w-3/4" v-if="!isMobileOrTablet">
              <SearchBar :placeholder="'¿Qué buscas?'" />
            </div>
            <button
              v-if="isMobileOrTablet"
              @click="openMenu"
              arial-label="menu"
              class="flex flex-col items-center justify-center relative xl:pl-2"
            >
              <img
                width="20"
                height="20"
                class="w-5"
                src="@/assets/svg/menu.svg"
                alt="menu"
              />
            </button>
            <div
              class="hover:bg-sanremo-primaryblue/10 rounded-full grid place-items-center transition-all duration-300 w-8 h-8 cursor-pointer"
              v-if="isMobile"
            >
              <button
                @click="
                  router.push({
                    path: `/${locale}/account`,
                  })
                "
                class="justify-center flex"
              >
                <img
                  width="20"
                  height="20"
                  src="@/assets/svg/account.svg"
                  class="w-5"
                  alt="account"
                />
              </button>
            </div>
          </div>
          <NuxtLink
            aria-label="home"
            class="mx-1 md:mx-3 flex items-center h-29 cursor-pointer"
            :to="`/${locale}`"
          >
            <Logo
              v-if="isMobileOrTablet"
              width="155"
              height="24"
              class="w-[155px] xs:w-[180px] ss:w-[195px]"
              :alt="logo"
            />
            <Logo
              v-else
              width="350"
              height="32"
              class="w-36 xs:w-44 ss:w-52 md:w-[300px] mdplus:w-[350px]"
              alt="logo"
            />
          </NuxtLink>
          <div class="flex flex-grow basis-0 justify-end gap-3 md:items-center">
            <div
              class="hover:bg-sanremo-primaryblue/10 hidden md:grid rounded-full place-items-center transition-all duration-300 w-8 h-8 cursor-pointer"
            >
              <button
                class="justify-center items-center md:flex"
                aria-label="account"
                @click="
                  router.push({
                    path: `/${locale}/account`,
                  })
                "
              >
                <img
                  width="20"
                  height="20"
                  src="@/assets/svg/account.svg"
                  class="w-5 h-5"
                  alt="account"
                />
              </button>
            </div>
            <NuxtLink
              :to="`/${locale}/account/favourites`"
              class="hover:bg-sanremo-primaryblue/10 rounded-full grid place-items-center transition-all duration-300 w-8 h-8 cursor-pointer"
            >
              <img
                width="20"
                height="20"
                src="@/assets/svg/favourite.svg"
                class="w-5"
                alt="favourites"
              />
            </NuxtLink>
            <div
              class="hover:bg-sanremo-primaryblue/10 rounded-full grid place-items-center transition-all duration-300 w-8 h-8 cursor-pointer"
              :class="changeCart ? 'animate-ping' : ''"
            >
              <button
                aria-label="Cesta"
                @click="openCart"
                class="flex justify-center relative"
              >
                <img
                  width="20"
                  height="20"
                  aria-label="shopping bag"
                  src="@/assets/svg/shoppingbag.svg"
                  alt="shopping bag"
                  class="w-5"
                />
                <ClientOnly><CartTotalItems /></ClientOnly>
              </button>
            </div>
          </div>
        </header>
        <div v-if="isMobileOrTablet">
          <SearchBar :placeholder="'¿Qué buscas?'" />
        </div>
      </div>
      <div class="relative w-screen h-full">
        <Menu
          :visibility="menu"
          @close="closeMenu"
          @hoverMenu="hoverMenu($event)"
        />
        <div
          class="absolute top-10 left-0 w-full h-[calc(100vh+200px)] bg-black opacity-40 z-200 pointer-events-none"
          :class="hovered ? ' block' : ' hidden'"
        ></div>
      </div>
    </div>
  </div>
</template>

<script setup>
const router = useRouter()
const { locale } = useI18n()

const { isMobile, isMobileOrTablet } = useDevice()

const { getTotalItems, show: showCart } = useCart()

const cartTotalItems = getTotalItems()
const changeCart = ref(false)

watch(cartTotalItems, () => {
  changeCart.value = true
  setTimeout(() => {
    changeCart.value = false
  }, 1000)
})

const hovered = ref(false)

const menu = ref(false)

const openMenu = () => {
  menu.value = true
  document.body.style.overflow = 'hidden'
}

const closeMenu = () => {
  menu.value = false
  document.body.style.overflow = 'auto'
}

const hoverMenu = (status) => {
  hovered.value = status
}

const openCart = () => {
  showCart()
}
</script>
