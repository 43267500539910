<template>
  <div class="mx-auto px-4 rounded bg-white relative max-w-7xl">
    <input
      id="dfsearchbox"
      type="text"
      :placeholder="placeholder != '' ? placeholder : '¿Qué buscas?'"
      class="appearance-none bg-transparent border-1 border-others-grey3 rounded font-normal tracking-wider outline-none w-full text-sm h-10 py-1.5 pl-4 pr-9"
    />
    <button
      aria-label="search"
      class="right-7 absolute top-1/2 translate-y-[-50%] h-19"
    >
      <img
        src="@/assets/svg/icons/icons-searchbar.svg"
        alt="icono de lupa de búsqueda"
      />
    </button>
  </div>
</template>

<script setup>
defineProps({
  placeholder: String,
})

const state = ref()

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ESTE CODIGO ES UN FIX TEMPORAL PARA DOOFINDER
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const handlePopState = (event) => {
  window.history.replaceState(makeCloneable(state.value), '')
}

onMounted(() => {
  const { back, current, forward, position, replaced, scroll } =
    window.history.state
  state.value = { back, current, forward, position, replaced, scroll }
  window.addEventListener('popstate', handlePopState)
})

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState)
})

function makeCloneable(obj, visited = new WeakSet()) {
  if (obj === null || typeof obj !== 'object') return obj
  if (visited.has(obj)) return null // Evita referencias circulares
  visited.add(obj)

  if (Array.isArray(obj)) {
    return obj.map((item) => makeCloneable(item, visited))
  }

  const clonedObj = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      if (typeof value === 'function') continue
      if (value instanceof Node) continue // Excluye nodos DOM

      clonedObj[key] = makeCloneable(value, visited)
    }
  }
  return clonedObj
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
</script>
